import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: "Pages",
  // },
  // //!--------------static Pages list-------
  // {
  //   id: "pages",
  //   title: "Pages",
  //   type: "item",
  //   icon: <Icon.Paperclip size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/pages",
  // // },

  // {
  //   id: "homePage",
  //   title: "Home",
  //   type: "item",
  //   icon: <Icon.Home size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/home-page",
  // },
  // {
  //   id: "staticPages",
  //   title: "Static Pages",
  //   type: "collapse",
  //   icon: <Icon.Anchor size={15} />,
  //   children: [
  //     {
  //       id: "FAQS",
  //       title: "FAQ's",
  //       type: "item",
  //       icon: <Icon.HelpCircle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/faqs",
  //     },
  //     {
  //       id: "About",
  //       title: "About Us",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/about",
  //     },
  //     {
  //       id: "privacyPolicy",
  //       title: "Privacy Policy",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/privacy-policy",
  //     },
  //     {
  //       id: "TermsOfUse",
  //       title: "Terms Of Use",
  //       type: "item",
  //       icon: <Icon.List size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/terms-of-use",
  //     },
  //   ],
  // },

  //!----------------Leads & Media--------
  {
    type: "groupHeader",
    groupTitle: "Projects",
    title: "Projects",
  },

  // //!-------------Projects--------

  {
    id: "ProjectsList",
    title: "Projects",
    type: "collapse",
    icon: <Icon.Book size={15} />,
    children: [
      {
        id: "ResidentialProjectsList",
        title: "Residential Projects",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/residential-projects/list",
      },
      {
        id: "CommercialProjectsList",
        title: "Commercial Projects",
        type: "item",
        icon: <Icon.Clipboard size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/commercial-projects/list",
      },
    ],
  },

  //!----------------Leads--------

  {
    id: "leads",
    title: "Leads List",
    type: "item",
    icon: <Icon.Award size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/leads-list",
  },
  //!----------------Blogs & Media--------
  {
    type: "groupHeader",
    groupTitle: "Blog",
    title: "Blog",
  },
  {
    id: "blogList",
    title: "Blog",
    type: "item",
    icon: <Icon.BookOpen size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/blog/list",
  },
  // //!-------------Gallery--------
  {
    type: "groupHeader",
    groupTitle: "Gallery",
    title: "Gallery",
  },
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;
